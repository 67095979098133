import DeviceIcon from "components/DeviceIcon/DeviceIcon";
import Text from "components/Text/Text";
import { observer } from "mobx-react-lite";
import getLinearScaledMultiRangeMetricProps from "utils/LinearScaledMultiRangeSafeVal/getLinearScaledMultiRangeMetricProps";
import isValidLinearScaledMultiRangeSafeVal from "utils/MetricValueVerifyUtils/isValidLinearScaledMultiRangeSafeVal";
import formatDate from "utils/formatDate";
import CurrentDeviceCardContentStatusDrawerItemHeader from "../../CardContentStatusDrawerItemGeneric/CardContentStatusDrawerItemGenericMetricContainer/CurrentDeviceCardContentStatusDrawerItemHeader/CurrentDeviceCardContentStatusDrawerItemHeader";
import MultiRangeSafeValMetric from "../../CardContentStatusDrawerItemGeneric/CardContentStatusDrawerItemGenericMetricContainer/CurrentDeviceCardContentStatusDrawerItemMetric/MultiRangeSafeValMetric/MultiRangeSafeValMetric";
import LinearScaledMultiRangeMetricContainerProps from "./utilities/LinearScaledMultiRangeMetricContainer.props";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";

export default observer(function LinearScaledMultiRangeMetricContainer({
    device,
    drawer,
    metric,
}: LinearScaledMultiRangeMetricContainerProps): JSX.Element {
    if (
        !device.state.dimensions[drawer.referenceID] ||
        !isValidLinearScaledMultiRangeSafeVal(
            device.state.dimensions[drawer.referenceID]
                ?.linearScaledMultiRangeSafeVal,
        )
    ) {
        return (
            <Text className="m-auto font-bold">
                Error from invalid safe val
            </Text>
        );
    }

    const linearScaledMultiRange =
        device.state.dimensions[drawer.referenceID]
            ?.linearScaledMultiRangeSafeVal!.linearScaledMultiRangeSafeVal;
    // Get the metric props to display, either scaled or unscaled depending on
    // whether the scale settings are set.
    const metricProps = getLinearScaledMultiRangeMetricProps(
        Number(metric.value),
        drawer,
        linearScaledMultiRange,
    );
    // Use the drawer (unconverted) name if the metric unit is unconverted
    const metricName =
        drawer.unit == metricProps.unit
            ? drawer.name
            : linearScaledMultiRange.scale.scaledDataType;
    // Round the value to be displayed to at most one decimal place
    const roundedMetricValue = Math.round(metricProps.value * 10) / 10;

    return (
        <div className="flex flex-row flex-1 bg-white">
            <div className="h-full grid grid-rows-2 px-2 pt-3">
                <div className="grid grid-cols-2 h-[4em]">
                    <div className="col-span-1 flex justify-center items-center">
                        <div>
                            <DeviceIcon
                                headers={device.headers}
                                overallHealth={
                                    device.deviceHealth[
                                        drawer.referenceID
                                    ] as HEALTH_STATUS
                                }
                                offline={device.state.offline}
                                image={drawer.image}
                            />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <CurrentDeviceCardContentStatusDrawerItemHeader
                            value={String(roundedMetricValue)}
                            unit={metricProps.unit}
                            type={drawer.type}
                        />
                    </div>
                </div>
                <span className="w-1/2 p-2 text-xs whitespace-wrap text-center overflow-hidden text-ellipsis font-sans font-semibold">
                    {metricName}
                </span>
            </div>

            <div className="flex flex-1 flex-col justify-between text-gray-900">
                <div className="flex flex-1">
                    <MultiRangeSafeValMetric {...metricProps} />
                </div>

                <span className="w-full p-2 text-xs whitespace-nowrap mx-auto text-center text-obsidian">
                    Updated: {formatDate(metric.updateDate)}
                </span>
            </div>
        </div>
    );
});
